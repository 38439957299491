import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: "Guilherme Dall'Agnol Deconto", // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello, my name is',
  name: "Guilherme Dall'Agnol Deconto",
  subtitle: "I'm an iOS / Android Developer",
  cta: 'About me',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpeg',
  paragraphOne: "Hi, I am a mobile developer from Brazil, currently, working as iOS Developer. Since I was a tennager, I've been fascinated with software development. Not just the software itself, but the hardware that makes it happen.",
  paragraphTwo: "I have a rich experience working with customers from around the world and the work I provide is of the highest quality, fully responsive, and tested in a wide range of devices. My code is well documented and easy to read, which means it can be maintained and scaled in the future.",
  paragraphThree: "For over five years I've been working with native technologies for mobile development and also I have a great experience with backend development and database structures.",
  paragraphFour: "Beside's programming, I enjoy playing video games and messing around with unity and unreal engine.",
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'safra.png',
    title: 'Safra National Bank of NY ',
    info: 'Safra National Bank of New York is a commercial banking institution engaged in international banking operations, as well as domestic business activities emphasizing short and intermediate term financing for business ventures in industry and trade.',
    info2: 'SNBNY is a part of the Safra Group of financial institutions.',
    url: 'https://www.safra.com.br/',
    repo: '', // if no repo, the button will not show up
  },
  // {
  //   id: nanoid(),
  //   img: 'aptiv.png',
  //   title: 'Aptiv',
  //   info: 'Aptiv is a global technology company that develops safer, greener and more connected solutions enabling the future of mobility.',
  //   info2: 'Aptiv is a global technology leader, with more than 160,000 people across 126 manufacturing facilities and 12 major technical centers worldwide. With a presence in 44 countries, we address mobility’s toughest challenges through our deep software and systems integration expertise, delivering market relevant solutions for our customers.',
  //   url: 'https://www.aptiv.com/',
  //   repo: '', // if no repo, the button will not show up
  // },
  // {
  //   id: nanoid(),
  //   img: 'bms.jpg',
  //   title: 'Bristol Myers Squibb',
  //   info: 'Bristol Myers Squibb is a global biopharmaceutical company whose mission is to discover, develop and deliver innovative medicines that help patients prevail over serious diseases.',
  //   info2: 'BMS combines the agility of a biotech with the reach and resources of an established pharmaceutical company to create a global leading biopharma company powered by talented individuals who drive scientific innovation.',
  //   url: 'https://www.bms.com/',
  //   repo: '', // if no repo, the button will not show up
  // },
  {
    id: nanoid(),
    img: 'omni.png',
    title: 'Omni Banco & Financeira',
    info: 'Omni Banking & Financial, present in the market for 25 years, is a solid institution and recognized for having one of the best credit services in Brazil.',
    info2: 'With exclusive agents throughout the country, the Omni has established itself as a major player in the financing of used vehicles and critical credit, expanding its operation in various sectors to meet consumers with less access to credit, seeking the realization of their plans personal and professional',
    url: 'https://www.omni.com.br/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'guarida.jpg',
    title: 'Guarida Imóveis',
    info: 'Focused on the principles of modernity, vision of the future and excellence in services, in 2000 the company became the first real estate company in the southern region of Brazil to be internationally certified by ISO 9001 in rental management, condominiums and intermediation in the purchase and sale of properties.',
    info2: '',
    url: 'https://www.guarida.com.br/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'topazio.png',
    title: 'Banco Topázio',
    info: 'B2B digital bank, with national operations and specialized in the foreign exchange and bank as a service segments. Through our expertise in technology and attentive to the evolution of the financial sector, we develop quality products and services pertinent to the needs of our clients.',
    info2: '',
    url: 'https://www.bancotopazio.com.br/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'digimais.jpg',
    title: 'Banco Digimais',
    info: 'We were born as a traditional bank and today, more than 35 years later, we have become 100% digital. Time has brought us experience, solidity and credibility, fundamental assets that we have to offer you, together with the security of our financial products and personalized service.',
    info2: '',
    url: 'https://www.bancodigimais.com.br/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'rci.png',
    title: 'RCIDirect',
    info: 'All the features and information necessary for the best service in the dealer network.',
    info2: '',
    url: 'https://apps.apple.com/br/app/rci-direct-brasil/id1481153154',
    repo: '', // if no repo, the button will not show up
  },
];

// PROJECTS DATA
export const workData = [
  {
    id: nanoid(),
    img: 'safra.png',
    title: 'Safra National Bank of NY ',
    info: 'Safra National Bank of New York is a commercial banking institution engaged in international banking operations, as well as domestic business activities emphasizing short and intermediate term financing for business ventures in industry and trade.',
    info2: 'SNBNY is a part of the Safra Group of financial institutions.',
    url: 'https://www.safra.com.br/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'aptiv.png',
    title: 'Aptiv',
    info: 'Aptiv is a global technology company that develops safer, greener and more connected solutions enabling the future of mobility.',
    info2: 'Aptiv is a global technology leader, with more than 160,000 people across 126 manufacturing facilities and 12 major technical centers worldwide. With a presence in 44 countries, we address mobility’s toughest challenges through our deep software and systems integration expertise, delivering market relevant solutions for our customers.',
    url: 'https://www.aptiv.com/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'bms.jpg',
    title: 'Bristol Myers Squibb',
    info: 'Bristol Myers Squibb is a global biopharmaceutical company whose mission is to discover, develop and deliver innovative medicines that help patients prevail over serious diseases.',
    info2: 'BMS combines the agility of a biotech with the reach and resources of an established pharmaceutical company to create a global leading biopharma company powered by talented individuals who drive scientific innovation.',
    url: 'https://www.bms.com/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'omni.png',
    title: 'Omni Banco & Financeira',
    info: 'Omni Banking & Financial, present in the market for 25 years, is a solid institution and recognized for having one of the best credit services in Brazil.',
    info2: 'With exclusive agents throughout the country, the Omni has established itself as a major player in the financing of used vehicles and critical credit, expanding its operation in various sectors to meet consumers with less access to credit, seeking the realization of their plans personal and professional',
    url: 'https://www.omni.com.br/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'guarida.jpg',
    title: 'Guarida Imóveis',
    info: 'Focused on the principles of modernity, vision of the future and excellence in services, in 2000 the company became the first real estate company in the southern region of Brazil to be internationally certified by ISO 9001 in rental management, condominiums and intermediation in the purchase and sale of properties.',
    info2: '',
    url: 'https://www.guarida.com.br/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'topazio.png',
    title: 'Banco Topázio',
    info: 'B2B digital bank, with national operations and specialized in the foreign exchange and bank as a service segments. Through our expertise in technology and attentive to the evolution of the financial sector, we develop quality products and services pertinent to the needs of our clients.',
    info2: '',
    url: 'https://www.bancotopazio.com.br/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'digimais.jpg',
    title: 'Banco Digimais',
    info: 'We were born as a traditional bank and today, more than 35 years later, we have become 100% digital. Time has brought us experience, solidity and credibility, fundamental assets that we have to offer you, together with the security of our financial products and personalized service.',
    info2: '',
    url: 'https://www.bancodigimais.com.br/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'rci.png',
    title: 'RCIDirect',
    info: 'All the features and information necessary for the best service in the dealer network.',
    info2: '',
    url: 'https://apps.apple.com/br/app/rci-direct-brasil/id1481153154',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'guilherme.deconto@acad.pucrs.br',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://www.instagram.com/decontogui/',
    },
    {
       id: nanoid(),
       name: 'facebook',
       url: 'https://www.facebook.com/guilherme.deconto.3/',
     },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/guilhermedeconto/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/GuilhermeDeconto',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
